<template>
    <div class="pagelogin">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <div class="loginbox">
            <div class="formbox">
                <div class="tabbox">
                    <el-tabs v-model="tabactive" type="card">
                        <el-tab-pane label="账号密码登录" name="1">
                            <div class="clear"></div>
                            <div class="inputbox">
                                <div class="ibox">
                                    <input type="text" v-model="accountname" placeholder="请输入账号/手机号" maxlength="20" />
                                </div>
                                <div class="ibox">
                                    <input v-model="accountpwd" :type="pwdtype" placeholder="请输入密码" maxlength="20" />
                                    <i v-if="pwdstatus" class="iconfont" @click.prevent="pwdbtn(false)">&#xe61b;</i>
                                    <i v-else class="iconfont" @click.prevent="pwdbtn(true)">&#xe61c;</i>
                                </div>
                                <div class="ibox">
                                    <div class="submitbtn" @click.prevent="login_acc()">登&nbsp;&nbsp;录</div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="验证码登录/注册" name="2">
                            <div class="clear"></div>
                            <div class="inputbox">
                                <div class="ibox">
                                    <input type="text" v-model="telnumber" placeholder="请输入手机号" maxlength="11" />
                                </div>
                                <div class="ibox">
                                    <input class="code" v-model="telcode" placeholder="请输入验证码" maxlength="6" />
                                    <div class="codebtn" v-if="isSend">{{ time }} s</div>
                                    <div class="codebtn" v-else @click="sendcode()">发送验证码</div>
                                </div>
                                <div class="ibox">
                                    <div class="submitbtn" @click.prevent="login_tel()">登 录 / 注 册</div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="txtbox">
                        <span class="txt toreg">
                            <el-checkbox v-model="agreement">我已阅读并接受</el-checkbox><a class="xy"
                                @click.prevent="useragreement()">《用户协议》</a>和<a class="xy"
                                @click.prevent="privacypolicy()">《隐私政策》</a>
                        </span>
                        <span class="txt" @click.prevent="forgetpwd()"><span class="getpwd">忘记密码？</span></span>
                    </div>
                    <div class="otherlogin">
                        <div class="otit"></div>
                    </div>
                </div>
                <div class="tipsbox" :class="tipsshowstyle"><span><i class="iconfont">&#xe64d;</i></span>{{ tipsshowtxt }}
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 用户协议 -->
        <div class="layer_agreement" :class="layerclass" v-if="agreementshow">
            <div class="layerbox" :class="layerboxclass">
                <div class="layercon">
                    <div class="tit">用户协议</div>
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                        <div class="content" v-html="agreementhtml"></div>
                    </el-scrollbar>
                    <div class="back" @click.prevent="agreementhide()">返回</div>
                </div>
            </div>
        </div>
        <!-- 隐私政策 -->
        <div class="layer_agreement" :class="layerclass" v-if="privacyshow">
            <div class="layerbox" :class="layerboxclass">
                <div class="layercon">
                    <div class="tit">隐私政策</div>
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                        <div class="content" v-html="privacyhtml"></div>
                    </el-scrollbar>
                    <div class="back" @click.prevent="privacyhide()">返回</div>
                </div>
            </div>
        </div>

        <!-- 忘记密码 -->
        <ForgetPwd ref="refforgetpwd"></ForgetPwd>

        <!-- 拼图滑动验证 -->
        <PuzzleVerification ref="verificationShow" :Mobile="telnumber" :onSuccess="slideVerifySuccess"></PuzzleVerification>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import PuzzleVerification from "@/components/PuzzleVerification.vue";
import ForgetPwd from "./components/forgetpwd/forgetpwd.vue";
import { getStore, setStore } from "../utils/utils";
import API from "../api/config";
export default {
    components: {
        PHeader,
        PFooter,
        ForgetPwd,
        PuzzleVerification
    },
    data() {
        return {
            pwdstatus: true,
            pwdtype: 'password',
            tabactive: "1",
            accountname: '',
            accountpwd: '',
            telnumber: '',
            telcode: '',
            agreement: false,
            tipsshow: false,
            tipsshowstyle: '',
            tipsshowtxt: '',
            isSend: false,
            time: 60,
            timer: null,
            layerclass: '',
            layerboxclass: '',
            agreementshow: false,
            agreementhtml: "",
            privacyshow: false,
            privacyhtml: ''
        }
    },
    methods: {
        //登录注册发送验证码
        sendcode() {
            if (!this.tipsshow) {
                if (!this.telnumber.trim()) {
                    this.verifytips("请输入手机号！"); return false;
                }
                else {
                    let str = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if (str.test(this.telnumber.trim()) == false) {
                        this.verifytips("手机号格式不正确！"); return false;
                    }
                }
                this.$refs.verificationShow.isVerificationShow = true;//显示 拼图滑动验证组件
                this.$refs.verificationShow.refreshImg();//刷新 拼图滑动验证组件
            }
        },
        //拼图滑动验证成功的回调
        slideVerifySuccess(par) {
            this.$refs.verificationShow.isVerificationShow = false;//隐藏拼图滑动验证组件
            // 发送手机验证码
            par.ValidateType = 0;
            API.Web.SendValidateCode(par).then(res => {
                if (res.Code === 0) {
                    this.handleTimer();//倒计时
                }
                else {
                    this.verifytips(res.Message);
                }
            });
        },
        //登录验证码倒计时
        handleTimer() {
            this.isSend = true;
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                }
                else {
                    clearInterval(this.timer);
                    this.isSend = false;
                    this.time = 60;
                }
            }, 1000);
        },
        //密码显示
        pwdbtn(b) {
            this.pwdstatus = b;
            if (b) {
                this.pwdtype = "password";
            }
            else {
                this.pwdtype = "text";
            }
        },
        //账号密码 登录
        login_acc() {
            if (!this.tipsshow) {
                if (!this.accountname) {
                    this.verifytips("请输入账号/手机号！"); return false;
                }
                if (!this.accountpwd) {
                    this.verifytips("请输入密码！"); return false;
                }
                if (!this.agreement) {
                    this.verifytips("请阅读《用户协议》和《隐私政策》并勾选接受"); return false;
                }
                //登录
                API.Web.Login({ UserName: this.accountname, Password: this.accountpwd, TerminalType: 0 }).then(res => {
                    if (res.Code === 0) {
                        setStore("LoginData", res.Data);

                        this.$store.dispatch('user/getStudentInfo').then(userInfo => {
                            if (userInfo.Exam) {

                                let obj = {
                                    ProvinceId: userInfo.Exam.ExamProvinceId,
                                    ProvinceName: userInfo.Exam.ExamProvinceName,
                                }

                                setStore('OrgProvince', obj)
                            }

                            this.$router.replace("/personal");
                        })
                    }
                    else {
                        this.verifytips(res.Message);
                    }
                });
            }
        },
        //手机验证码 登录 / 注册
        login_tel() {
            if (!this.tipsshow) {
                if (!this.telnumber) {
                    this.verifytips("请输入手机号！"); return false;
                }
                else {
                    let str = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if (str.test(this.telnumber.trim()) == false) {
                        this.verifytips("手机号格式不正确！"); return false;
                    }
                }
                if (!this.telcode) {
                    this.verifytips("请输入验证码！"); return false;
                }
                if (!this.agreement) {
                    this.verifytips("请阅读《用户协议》和《隐私政策》并勾选接受"); return false;
                }
                //登录
                API.Web.ValidateCodeLoginOrRegister({ TerminalType: 0, ValidateType: 0, Mobile: this.telnumber, ValidateCode: this.telcode }).then(res => {
                    if (res.Code === 0) {
                        setStore("LoginData", res.Data);

                        this.$store.dispatch('user/getStudentInfo').then(userInfo => {

                            if (userInfo.Exam) {

                                let obj = {
                                    ProvinceId: userInfo.Exam.ExamProvinceId,
                                    ProvinceName: userInfo.Exam.ExamProvinceName,
                                }

                                setStore('OrgProvince', obj)
                            }

                            this.$router.replace("/personal");
                        })

                    }
                    else {
                        this.verifytips(res.Message);
                    }
                });
            }
        },
        //登录注册验证提示
        verifytips(txt) {
            this.tipsshow = true;
            this.tipsshowtxt = txt;
            this.tipsshowstyle = "ani_show";
            let st = setTimeout(() => {
                this.tipsshowtxt = "";
                this.tipsshowstyle = "";
                this.tipsshow = false;
                clearTimeout(st);
            }, 1500);
        },
        //用户协议
        useragreement(){
            this.layerclass='layershow';
            this.layerboxclass="layerboxshow";
            this.agreementshow=true;
            API.Web.GetContentDetail({contentKey:"Agreement_User"}).then(res => {
                if(res.Code===0&&res.Data!=null){
                    this.agreementhtml=res.Data.RichTextContent;
                }
            });
        },
        agreementhide() {
            this.layerclass = 'layerhide';
            this.layerboxclass = "layerboxhide";
            let st = setTimeout(() => {
                this.agreementshow = false;
                clearTimeout(st);
            }, 300);
        },
        //隐私政策
        privacypolicy(){
            this.layerclass='layershow';
            this.layerboxclass="layerboxshow";
            this.privacyshow=true;
            API.Web.GetContentDetail({contentKey:"Agreement_Privacy"}).then(res => {
                if(res.Code===0&&res.Data!=null){
                    this.privacyhtml=res.Data.RichTextContent;
                }
            });
        },
        privacyhide() {
            this.layerclass = 'layerhide';
            this.layerboxclass = "layerboxhide";
            let st = setTimeout(() => {
                this.privacyshow = false;
                clearTimeout(st);
            }, 300);
        },
        //找回密码
        forgetpwd() {
            this.$refs.refforgetpwd.layerclass = 'layershow';
            this.$refs.refforgetpwd.layerboxclass = "layerboxshow";
            this.$refs.refforgetpwd.forgetpwdshow = true;
        },
        //微信登录
        wechatloginbtn() {
            if (!this.tipsshow) {
                this.verifytips("抱歉，微信登录方式暂未开放！");
            }
        },
    }
}
</script>
<style lang="less" scoped>
.loginbox {
    width: 100%;
    height: 660px;
    background: url(../../src/assets/images/login_back1.jpg) no-repeat center top / cover;

    .formbox {
        max-width: 1420px;
        height: 100%;
        position: relative;
        margin: auto;

        .tipsbox {
            position: absolute;
            width: 440px;
            height: 44px;
            line-height: 44px;
            color: #F56C6C;
            text-indent: 10px;
            font-size: 14px;
            top: 0px;
            right: 49px;
            z-index: 2;
            opacity: 0;
            background-color: #fef0f0;
            border: 1px solid #fde2e2;
            border-radius: 5px;

            span {
                width: 12px;
                height: 12px;
                display: block;
                float: left;
                margin-left: 15px;
                margin-top: 16.5px;
                background-color: #F56C6C;
                line-height: 5.5px;
                text-indent: 3.5px;
                border-radius: 10px;

                i {
                    font-size: 6px;
                    color: #fff;
                }
            }
        }

        .tipsbox.ani_show {
            animation: ani_show 1.5s ease-out 0s 1;
            -webkit-animation: ani_show 1.5s ease-out 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;

            @-webkit-keyframes ani_show {
                0% {
                    top: 0px;
                    opacity: 0;
                }

                10% {
                    top: 120px;
                    opacity: 1;
                }

                90% {
                    top: 120px;
                    opacity: 1;
                }

                100% {
                    top: 0px;
                    opacity: 0;
                }
            }

            @keyframes ani_show {
                0% {
                    top: 0px;
                    opacity: 0;
                }

                10% {
                    top: 120px;
                    opacity: 1;
                }

                90% {
                    top: 120px;
                    opacity: 1;
                }

                100% {
                    top: 0px;
                    opacity: 0;
                }
            }
        }

        .tabbox {
            position: absolute;
            right: 20px;
            top: 75px;
            width: 500px;
            height: 500px;
            border: 1px solid #ddd;
            // border-radius: 5px;
            background-color: #fff;
            overflow: hidden;
            .otherlogin {
                width: 100%;
                text-align: center;
                margin-top: 55px;
                .otit {
                    width: 100%;
                    height: 89px;
                    position: relative;
                    border-top: 1px solid #e0e0e0;
                    user-select: none;
                    background:url(../assets/images/trophy.png) no-repeat center center / 100%;
                    background-color: rgba(var(--themecolor),0.9);
                }
            }

            .inputbox {
                margin: 20px 30px 0;

                .ibox {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    margin-bottom: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        line-height: 1;
                        line-height: 48px;
                        text-indent: 15px;
                        border: 1px solid #dddddd;
                        color: #666;
                        font-size: 14px;
                    }

                    input:focus {
                        border: 1px solid rgb(var(--themecolor));
                    }

                    i {
                        position: absolute;
                        width: 40px;
                        height: 48px;
                        right: 1px;
                        top: 1px;
                        line-height: 50px;
                        text-align: center;
                        color: #999;
                        font-size: 18px;
                        border-radius: 5px;
                        user-select: none;
                    }

                    i:hover {
                        cursor: pointer;
                        opacity: 0.6;
                        color: rgb(var(--themecolor));
                    }

                    .code {
                        width: 320px;
                    }

                    .codebtn {
                        width: 100px;
                        height: 50px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        line-height: 50px;
                        text-align: center;
                        color: #666;
                        font-size: 14px;
                        border: 1px solid #d8d8d8;
                        user-select: none;
                    }

                    .codebtn:hover {
                        cursor: pointer;
                        color: rgb(var(--themecolor));
                    }

                    .submitbtn {
                        margin-top: 40px;
                        color: #fff;
                        height: 50px;
                        line-height: 50px;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        background-color: rgb(var(--themecolor));
                        opacity: 0.75;
                        border-radius: 5px;
                        user-select: none;
                        transition: opacity 0.2s;
                        -webkit-transition: opacity 0.2s;
                    }

                    .submitbtn:hover {
                        opacity: 0.9;
                        cursor: pointer;
                    }
                }
            }

            .txtbox {
                margin: 0px 30px;
                height: 30px;

                span.txt {
                    display: block;
                    height: 30px;
                    line-height: 30px;
                    color: #666;
                    font-size: 14px;

                    .getpwd {
                        float: right;
                        user-select: none;
                    }

                    .getpwd:hover {
                        color: rgb(var(--themecolor));
                        opacity: 0.75;
                        cursor: pointer;
                    }
                }

                span.toreg {
                    float: left;

                    a.xy {
                        color: rgb(var(--themecolor));
                        opacity: 0.75;
                        user-select: none;
                    }

                    a.xy:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.layer_agreement.layershow {
    animation: layershow 0.3s ease 0s 1;
    -webkit-animation: layershow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layershow {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes layershow {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}

.layer_agreement.layerhide {
    animation: layerhide 0.3s ease 0s 1;
    -webkit-animation: layerhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerhide {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes layerhide {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

.layer_agreement {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.5);

    .layerbox.layerboxshow {
        animation: layerboxshow 0.3s ease 0s 1;
        -webkit-animation: layerboxshow 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        @-webkit-keyframes layerboxshow {
            from {
                top: 0px;
            }

            to {
                top: 40px;
            }
        }

        @keyframes layerboxshow {
            from {
                top: 0px;
            }

            to {
                top: 40px;
            }
        }
    }

    .layerbox.layerboxhide {
        animation: layerboxhide 0.3s ease 0s 1;
        -webkit-animation: layerboxhide 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        @-webkit-keyframes layerboxhide {
            from {
                top: 40px;
            }

            to {
                top: 0px;
            }
        }

        @keyframes layerboxhide {
            from {
                top: 40px;
            }

            to {
                top: 0px;
            }
        }
    }

    .layerbox {
        width: 700px;
        height: calc(100% - 80px);
        position: absolute;
        top: 0px;
        left: calc(50% - 350px);
        background-color: #fff;
        overflow: hidden;
        position: relative;

        .layercon {
            width: 100%;
            height: 100%;
            position: relative;

            .tipsbox {
                position: absolute;
                width: 440px;
                height: 44px;
                line-height: 44px;
                color: #F56C6C;
                text-indent: 10px;
                font-size: 14px;
                top: 0px;
                left: 130px;
                z-index: 2;
                opacity: 0;
                background-color: #fef0f0;
                border: 1px solid #fde2e2;
                border-radius: 5px;

                span {
                    width: 12px;
                    height: 12px;
                    display: block;
                    float: left;
                    margin-left: 15px;
                    margin-top: 16.5px;
                    background-color: #F56C6C;
                    line-height: 5.5px;
                    text-indent: 3.5px;
                    border-radius: 10px;

                    i {
                        font-size: 6px;
                        color: #fff;
                    }
                }
            }

            .scrollbox {
                margin: 0px 30px;
                height: calc(100% - 120px);
                overflow-x: hidden;

                .content {
                    color: #666;
                    font-size: 14px;
                    line-height: 26px;
                    text-indent: 28px;
                }

                .inputbox {
                    margin: 50px 50px 0;

                    i.success {
                        color: #159405;
                        font-size: 100px;
                        display: block;
                        text-align: center;
                        padding-top: 20px;
                    }

                    .successtxt {
                        color: #666;
                        font-size: 18px;
                        text-align: center;
                        margin-top: 10px;
                    }

                    .marktxt {
                        color: #888;
                        font-size: 14px;
                        margin-top: 10px;
                        text-align: center;
                    }

                    .ibox {
                        width: 100%;
                        height: 50px;
                        position: relative;
                        margin-bottom: 20px;

                        input {
                            width: 100%;
                            height: 100%;
                            line-height: 1;
                            line-height: 48px;
                            text-indent: 15px;
                            border: 1px solid #dddddd;
                            color: #666;
                            font-size: 14px;
                        }

                        input:focus {
                            border: 1px solid rgb(var(--themecolor));
                        }

                        i {
                            position: absolute;
                            width: 40px;
                            height: 48px;
                            right: 1px;
                            top: 1px;
                            line-height: 50px;
                            text-align: center;
                            color: #999;
                            font-size: 18px;
                            background-color: #fff;
                            border-radius: 5px;
                        }

                        i:hover {
                            cursor: pointer;
                            opacity: 0.6;
                            color: rgb(var(--themecolor));
                        }

                        .code {
                            width: 420px;
                        }

                        .codebtn {
                            width: 100px;
                            height: 50px;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            line-height: 50px;
                            text-align: center;
                            color: #666;
                            font-size: 14px;
                            border: 1px solid #d8d8d8;
                            user-select: none;
                        }

                        .codebtn:hover {
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                        }

                        .submitbtn {
                            margin-top: 40px;
                            color: #fff;
                            height: 50px;
                            line-height: 50px;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                            background-color: rgb(var(--themecolor));
                            opacity: 0.75;
                            border-radius: 5px;
                            transition: opacity 0.2s;
                            -webkit-transition: opacity 0.2s;
                        }

                        .submitbtn.b {
                            background-color: #ddd;
                            color: #888;
                        }

                        .submitbtn:hover {
                            opacity: 0.9;
                            cursor: pointer;
                        }
                    }
                }
            }

            .tit {
                text-align: center;
                height: 60px;
                line-height: 60px;
                color: #333;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 1px;
                border-bottom: 1px solid #e8e9e9;
            }

            .back {
                width: 100%;
                height: 44px;
                line-height: 44px;
                position: absolute;
                bottom: 0;
                left: 0px;
                z-index: 66;
                background-color: #ddd;
                color: #666;
                font-size: 14px;
                letter-spacing: 2px;
                text-align: center;
                user-select: none;
            }

            .back:hover {
                cursor: pointer;
                opacity: 0.85;
                color: rgb(var(--themecolor));
            }
        }
    }
}
</style>
<style>
.loginbox .formbox .tabbox .el-tabs__nav {
    width: 100%;
    height: 60px;
    border: 0;
}

.loginbox .formbox .tabbox .el-tabs__item {
    width: 50%;
    padding: 0;
    text-align: center;
    height: 100%;
    line-height: 58px;
    color: #666;
    font-size: 15px;
    user-select: none;
}

.loginbox .formbox .tabbox .el-tabs__item.is-active {
    color: rgb(var(--themecolor));
}

.loginbox .formbox .tabbox .el-tabs__item.is-focus {
    box-shadow: none;
}

.loginbox .formbox .el-checkbox:hover {
    opacity: 0.85;
}

.loginbox .formbox .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}

.loginbox .formbox .el-checkbox__inner {
    border-color: rgb(var(--themecolor));
    opacity: 0.8;
}

.loginbox .formbox .el-checkbox__inner:hover {
    border-color: rgb(var(--themecolor));
}

.loginbox .formbox .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #666;
}

.layer_agreement .layerbox .layercon .el-scrollbar__wrap {
    overflow-x: hidden;
}

.layer_agreement .layerbox .layercon .el-step__head.is-finish,
.layer_agreement .layerbox .layercon .el-step__title.is-finish {
    color: rgb(var(--themecolor));
    border-color: rgb(var(--themecolor));
}

.layer_agreement .layerbox .layercon .el-step__head.is-process,
.layer_agreement .layerbox .layercon .el-step__title.is-process {
    color: #666;
    border-color: #666;
}
</style>